<template>
  <div class="text-left p-5">
    <div class="fs-3 fw-bolder py-2">Terms Of Use</div>
    <div class="pt-3">
      Zemeto Limited is a app development company and app publisher. We deliver top ranked Apps to worldwide app stores.
      Company information: 3021, Pavlou Nirvana & Aipeias, 4 Alpha Tower, 1st Floor, Flat/Office 11, Republic of Cyprus.
      Our Apps are created for numerous different devices (phone, tablet etc.). We create Apps that can be downloaded
      freely, functioning on the basis of an advertising model. For many of our Apps, we also create a paid version that
      does not contain advertising. In general, if you do not want us to use your data to tailor the ads that are
      displayed within the App, you can choose to use a version that does not contain advertising.
    </div>
    <div class="pt-3">
      We makes Apps available to you subject to the following Terms of Use. Do not use the App if you do not accept
      these Terms of Use. If you use the App, you affirmatively accept these Terms of Use.
    </div>

    <div class="fs-5 fw-bold pt-5">1. Use of Apps</div>
    <div class="pt-3">
      1.1 You agree to use the App only for purposes that are permitted by (a) these Terms of Use and (b) any applicable
      law, regulation or generally accepted practices or guidelines in the relevant jurisdictions. You agree to comply
      with all local laws and regulations regarding the download, installation and/or use of the App.
    </div>
    <div class="pt-3">
      1.2 By using the App, you are consenting to our processing of data as set forth in our Privacy Policy.
    </div>
    <div class="pt-3">
      1.3 You agree that Zemeto Limited is not responsible or liable for any content accessed from the App or
      third-party websites. You agree that you are solely responsible for (and that Zemeto Limited has no responsibility
      to you or to any third party for) your use of the App.
    </div>
    <div class="pt-3">
      1.4 You acknowledge that the App may communicate with our servers from time to time to check for updates to the
      App, such as bug fixes, patches, enhanced functions, missing plug-ins and new versions. By installing the App, you
      agree to such automatically requested and received updates.
    </div>
    <div class="pt-3">
      1.5 You agree that we may stop (permanently or temporarily) providing the App (or any features within the App) to
      you or to users generally at our sole discretion, without prior notice to you. You agree that if we disable access
      to the App, you may be prevented from accessing the App or certain features within the App.
    </div>
    <div class="pt-3">
      1.6 You may not distribute, publish, or send through an App: (1) any spam, including any unsolicited
      advertisements, solicitations, commercial messages, informational announcements, or promotional messages of any
      kind; (2) chain mail; (3) numerous copies of the same or substantially similar messages; (4) empty messages; (5)
      messages that contain no substantive content; (6) very large messages or files that disrupt a server, account,
      newsgroup, or chat service; or (7) any message that is categorized as ‘phishing’.
    </div>

    <div class="fs-5 fw-bold pt-5">2. Intellectual Property Rights</div>
    <div class="pt-3">
      2.1 The App, and all content included on or within the App, such as the user interface, the compilation of the
      content and arrangement of the website, and all text, graphics, pictures, sound, video, data, applications and
      other content, is the property of us, our licensors or content suppliers and is protected by copyright and other
      intellectual property laws. Reproduction or redistribution of such content except as expressly permitted by these
      Terms of Use is prohibited.
    </div>
    <div class="pt-3">
      2.2 We grant you a personal revocable, non-exclusive, non-transferable license to access and make personal use of
      our App. You may not download, reverse engineer, decompile, disassemble, or modify it, or any portion of it,
      except with our express written consent. The App may not be copied, sold, resold or otherwise exploited for any
      commercial purpose without our express written consent. You may not link, frame or utilize framing techniques to
      enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of
      the App without our prior written consent. Your unauthorized use of the App will immediately terminate the limited
      license granted by Zemeto Limited.
    </div>

    <div class="fs-5 fw-bold pt-5">3. Subscription Fees And Payment</div>
    <div class="pt-3">
      3.1. Certain features of the Service may be offered on a subscription basis for a fee. You may purchase a
      subscription directly from the Company or through an App Store either by (1) paying a subscription fee in advance
      on a recurring interval disclosed to you prior to your purchase; or (2) pre-payment giving you access to the
      Service for a specific time period (together or separately “Purchase”).
    </div>
    <div class="pt-3">
      3.2. To the maximum extent permitted by applicable laws, we may change Purchase fees at any time. We will give you
      reasonable notice of any such pricing changes by posting the new prices on or through the App and/or by sending
      you an e-mail notification, or in other prominent way. If you do not wish to pay the new fees, you can cancel the
      applicable subscription prior to the change going into effect or/and abstain from pre-paying for access to the
      Service.
    </div>
    <div class="pt-3">
      3.4. By signing up for certain subscriptions, you agree that your subscription may be automatically renewed.
      Unless you cancel your subscription you authorize us and the App Stores to charge you for the renewal term. The
      period of auto-renewal will be the same as your initial subscription period unless otherwise disclosed to you on
      the Service. The renewal rate will be no more than the rate for the immediately prior subscription period,
      excluding any promotional and discount pricing, unless we notify you of a rate change prior to your auto-renewal.
      You must cancel your subscription in accordance with the cancellation procedures disclosed to you for the
      particular subscription. We will not refund fees that may have accrued to your account and will not prorate fees
      for a cancelled subscription.
    </div>
    <div class="pt-3">
      3.5. We may offer a trial subscription for the Service. Trial provides you access to the Service for a period of
      time, with details specified when you sign up for the offer. Unless you cancel before the end of the trial, or
      unless otherwise stated, your access to the Service will automatically continue and you will be billed the
      applicable fees for the Service. It is ultimately your responsibility to know when the free or paid trial will
      end. We reserve the right, in our absolute discretion, to modify or terminate any trial offer, your access to the
      Service during the free or paid trial, or any of these terms without notice and with no liability. We reserve the
      right to limit your ability to take advantage of multiple free or paid trials.
    </div>
    <div class="pt-3">
      3.6. The Service and your rights to use it expire at the end of the paid period of your subscription. If you do
      not pay the fees or charges due, we may make reasonable efforts to notify you and resolve the issue; however, we
      reserve the right to disable or terminate your access to the Service (and may do so without notice).
    </div>
    <div class="pt-3">
      3.7. Subscriptions purchased via an App Store are subject to such App Store’s refund policies. This means we
      cannot grant refunds. You will have to contact an App Store support.
    </div>
    <div class="pt-3">
      3.8. You agree that the Purchase is final, that Company will not refund any transaction once it has been made and
      that the Purchase cannot be canceled. When you make the Purchase, you acknowledge and agree that all Purchases are
      non-refundable or exchangeable. Since the Service is of a digital nature, we cannot accept any request for refunds
      and therefore your right of withdrawal is lost at this point. The Company may provide refunds at its own
      discretion and subject to our policies that may be published from time to time.
    </div>
    <div class="pt-3">
      3.9. We may post clear and conspicuous subscription terms from time to time on our websites and within the Apps.
    </div>
    <div class="pt-3">
      3.10. Cancellation. Your subscription renews automatically at the end of each period until you cancel. Note that
      deleting the app does not cancel your subscriptions.
      <br/>
      <strong>If you purchased a subscription or enabled trial on the App Store:</strong> You may cancel a free trial or
      a subscription
      anytime by turning off auto-renewal through your Apple ID account settings. To avoid being charged, cancel the
      subscription in your Apple ID account settings at least 24 hours before the end of the free trial or the current
      subscription period. You alone can manage your subscriptions. Learn more about managing subscriptions (and how to
      cancel them) on Apple support page.
      <br/>
      <strong>If you purchased a subscription or enabled a trial on our websites:</strong> To avoid being charged cancel
      your
      subscription before the end of the then-current period. You may cancel your subscription purchased on our website
      by submitting the cancellation request to <a href="mailto:info@simple-recorder.com">info@simple-recorder.com</a> at
      least one day before each renewal date.
    </div>

    <div class="fs-5 fw-bold pt-5">4. No Warranties and Limitation of Liability</div>
    <div class="pt-3">
      4.1 Zemeto Limited does not warrant that the App, its functions, or content will be uninterrupted or error free,
      or that defects will be corrected. Zemeto Limited makes no representation or warranty as to the accuracy or
      fitness for use of any offers, advertisement or third party content presented in connection with the App. The App
      is provided on an ‘as is’ basis. You agree that your use of the App is at your sole risk.
    </div>
    <div class="pt-3">
      4.2 Zemeto Limited will not be liable to you for any damages of any kind arising from your use or inability to use
      the App. Our liability for monetary damages for any claims that you may have against us is limited to the amount
      you actually paid for the App.
    </div>

    <div class="fs-5 fw-bold pt-5">5. Applicable Law and Dispute Resolution</div>
    <div class="pt-3">
      5.1 In the event a dispute arises between you and Zemeto Limited, we will use reasonable endeavors to resolve such
      dispute amicably. If we cannot agree on a resolution, you agree that the dispute will be resolved in Cyprus,
      unless applicable mandatory law provides that you are entitled to address a court in another jurisdiction.
    </div>
    <div class="pt-3">
      5.2 Any claim arising out of or in connection with your use of or inability to use the App must be brought within
      one (1) year after the event or such claim is barred.
    </div>

    <div class="fs-5 fw-bold pt-5">6. Changes</div>
    <div class="pt-3">
      6.1 These Terms of Use may be updated from time to time. We will notify you of any changes. You are advised to
      consult these Terms of Use regularly for any changes.
    </div>
    <div class="pt-3">
      These Terms of Use were updated June 2022.
    </div>
  </div>
</template>

<script>
import config from "@/config";

export default {
  beforeMount() {
    this.initClarity(config.clarityId.onboardingId)
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    this.scrollToTop();
  },
}
</script>

<style lang="scss" scoped>
.text-left {
  text-align: left;
}
</style>
